/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useOnEnterOrSpacebar from 'hooks/useOnEnterOrSpacebar';
import usePDPOptionsClearanceData from 'data-hooks/useClearanceData/usePDPOptionsClearanceData';
import Badge from 'components/consumer/Badge';
import BrandTooltip from 'components/consumer/BrandTooltip';
import MaterialThumbnail from 'components/consumer/MaterialThumbnail';
import InfoIcon from 'components/icons/IconInfoNew';
import { colorGrayLight2 } from 'styles/layout/colors';
import Placeholder from 'components/consumer/Placeholder';
import usePDPBasicDetails from '../state/context/usePDPBasicDetails';

const hasBothWords = (str, firstWord, secondWord) => {
  if (!str) {
    return false;
  }

  const strLowerCase = str.toLowerCase();
  return strLowerCase.includes(firstWord) && strLowerCase.includes(secondWord);
};
const getStockQuantityToShow = (selectedOptionsStockQty, isDiningChair) => {
  if (selectedOptionsStockQty <= 5) {
    return selectedOptionsStockQty;
  }
  if (isDiningChair && selectedOptionsStockQty >= 12) {
    return 12;
  }
  return 5;
};
const PDPActiveFabric = ({
  isEditOrder = false,
  isModularBundleProduct,
  isSelectedOptionsQuickship,
  loading,
  onClick,
  selectedOption,
  selectedOptionsStockQty,
  showAdditionalCost = true,
  showInfoIcon = true,
  size = 'md',
  materialNameClass = '',
  fullHeight = false,
  className = '',
}) => {
  const [showClearanceBadge, setShowClearanceBadge] = useState(false);
  const { data: basicDetailsData } = usePDPBasicDetails();
  const { type } = basicDetailsData ?? {};
  // if product type contains both dining and chair .
  const isDiningChair = hasBothWords(type?.name, 'dining', 'chair');
  const { data: pdpClearanceData } = usePDPOptionsClearanceData();

  // too many updates on the pdpClearance data
  const clearanceOptions = useMemo(
    () => Object.keys(pdpClearanceData?.optionsClearanceData || {}),
    [pdpClearanceData?.optionsClearanceData]
  );

  const activeFabricRef = useRef(null);
  useOnEnterOrSpacebar(!!onClick, activeFabricRef, onClick);

  const isFlashSale =
    pdpClearanceData?.optionsClearanceData?.[selectedOption.sku]?.flashSale ===
      '1' || false;

  useEffect(() => {
    if (clearanceOptions.length > 0 && selectedOption?.sku) {
      const isSelectedOptionOnClearance =
        clearanceOptions.includes(selectedOption.sku) || false;
      if (showClearanceBadge !== isSelectedOptionOnClearance) {
        setShowClearanceBadge(isSelectedOptionOnClearance);
      }
    }
  }, [clearanceOptions, selectedOption, showClearanceBadge]);

  const showFlashSale = !isFlashSale && showClearanceBadge;

  if (loading) {
    return (
      <div className="flex justify-start">
        <Placeholder
          type="rect"
          loading
          className="mb-2 lg:mb-1.5"
          style={{ height: '23px', width: '175px' }}
        />
      </div>
    );
  }

  if (!selectedOption) {
    return null;
  }
  const label = `More information about ${selectedOption.label ||
    selectedOption.value}`;

  const formattedAddlCost = cost => (cost < 0 ? `-$${cost * -1}` : `$${cost}`);

  return (
    <div className="flex justify-start">
      <div
        className={`flex items-center pr-0.5 border-2 border-solid border-transparent focus-visible:border-brand focus-visible:border-radius-2xl focus-visible:rounded focus-visible:outline-none mb-2 lg:mb-1.5 ${className}`}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={onClick ? '0' : undefined}
        aria-label={label}
        title={label}
        ref={activeFabricRef}
      >
        <div className="w-4 h-4 mr-1">
          <MaterialThumbnail
            image={selectedOption.image}
            label={selectedOption.label || selectedOption.value}
            materialId={selectedOption.materialId}
            upholsteryOptionId={selectedOption.upholsteryOptionId}
            description={selectedOption.description}
            isRound
            fullHeightThumbnail={fullHeight}
          />
        </div>

        <h3
          className={classNames(
            `hover:underline font-bold mx-1 ${materialNameClass}`,
            {
              'text-xs': size === 'xs',
              'text-sm': size === 'sm',
              'text-base': size === 'md',
            }
          )}
        >
          {selectedOption.label || selectedOption.value}
          {selectedOption.exclude_from_sale ? ' - Limited Edition' : null}
        </h3>
        {selectedOption?.additionalCost && showAdditionalCost ? (
          <div
            className={classNames('font-bold flex items-center', {
              'text-xs': size === 'xs',
              'text-sm': size === 'sm',
              'text-base': size === 'md',
            })}
          >
            {`  (`}
            {isEditOrder &&
              selectedOption?.origAddlCost &&
              selectedOption.origAddlCost !== selectedOption.additionalCost && (
                <div className="line-through text-xs px-0.5">
                  {formattedAddlCost(selectedOption?.origAddlCost)}
                </div>
              )}
            <div
              className={classNames('font-bold text-xs', {
                'text-red': isEditOrder,
              })}
            >
              {formattedAddlCost(selectedOption?.additionalCost)}
              {isModularBundleProduct ? '/piece' : ''}
            </div>
            )
          </div>
        ) : isSelectedOptionsQuickship && selectedOptionsStockQty ? (
          <div className="text-red text-xs font-bold ml-1.5">
            <span className="mr-1.5">&bull;</span>
            <span>{`Only ${getStockQuantityToShow(
              selectedOptionsStockQty,
              isDiningChair
            )} left!`}</span>
          </div>
        ) : null}

        {showInfoIcon && (
          <InfoIcon
            className="cursor-pointer ml-2"
            color={colorGrayLight2}
            height={13}
            width={13}
            aria-hidden="true"
          />
        )}
        {showFlashSale && (
          <BrandTooltip
            inactiveOnMobile
            showInfoIcon={false}
            customIcon={
              <Badge className="w-fit bg-yellow-light px-2">
                <span className="text-gray">Final Sale</span>
              </Badge>
            }
            arrowOffset={32}
          >
            This final sale product is eligible for cancellation within 24 hours
            of order placement. Ineligible for returns except for items arriving
            damaged & documented by carrier. Ineligible for warranty claims
            unless reports of defects are submitted within 14 days of delivery,
            cosmetic damage isn&apos;t covered.
          </BrandTooltip>
        )}
      </div>
    </div>
  );
};

PDPActiveFabric.propTypes = {
  isEditOrder: PropTypes.bool,
  isModularBundleProduct: PropTypes.bool,
  isSelectedOptionsQuickship: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  selectedOption: PropTypes.shape({
    exclude_from_sale: PropTypes.bool,
    additionalCost: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    materialId: PropTypes.number,
    origAddlCost: PropTypes.number,
    upholsteryOptionId: PropTypes.number,
    value: PropTypes.string,
    sku: PropTypes.string,
  }),
  selectedOptionsStockQty: PropTypes.number,
  showAdditionalCost: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  materialNameClass: PropTypes.string,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
};

export default PDPActiveFabric;
