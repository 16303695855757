import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import avo from 'analytics/avo';
import notificationBannerHelper from 'components/consumer/NotificationBanner/utils';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import MaterialThumbnailUI from './MaterialThumbnailUI';

const MaterialThumbnail = ({
  color,
  customToggleFlyOut = false,
  customToggleFlyOutData = null,
  fabricFavProps = {},
  fullHeightThumbnail = false,
  image,
  isDisabled = false,
  isFavMaterial,
  isInFlashSale = false,
  isRound = false,
  isSelected,
  isUserLoggedIn,
  label,
  loading,
  materialApplicableForProduct = true,
  materialId,
  onClick,
  onHover,
  show = true,
  toggleFlyOut,
  upholsteryOptionId,
  variant,
}) => {
  const {
    actions: { setOptionAsFav, removeOptionAsFav } = {},
    data: { favMaterials, favProductData } = {},
  } = fabricFavProps;
  const favMaterialCount = favMaterials ? Object.keys(favMaterials).length : 0;
  const favParams = setOptionAsFav
    ? {
        fabricId: materialId,
        fabricName: label,
        fabricColor: color,
        location: 'PDP',
        ...favProductData,
      }
    : {};
  const isTouchDevice = useIsTouchDevice();
  const lastTapRef = useRef(null);
  const showNotificationBannerRef = useRef(null);
  const DOUBLE_PRESS_DELAY = 300;

  useEffect(() => {
    const showFavNotificationBanner = () => {
      notificationBannerHelper.showMsg('FabricFavoritedBanner', {
        fabricName: label,
        isFavorited: isFavMaterial,
        isUserLoggedIn,
        favNum: favMaterialCount,
      });
    };

    if (
      showNotificationBannerRef.current &&
      favMaterialCount === 1 &&
      isFavMaterial
    ) {
      showFavNotificationBanner();
      showNotificationBannerRef.current = false;
    }
  }, [
    favMaterialCount,
    isFavMaterial,
    isUserLoggedIn,
    label,
    showNotificationBannerRef,
  ]);

  return (
    <MaterialThumbnailUI
      show={show}
      image={image}
      isSelected={isSelected}
      label={label}
      loading={loading}
      fullHeightThumbnail={fullHeightThumbnail}
      variant={variant}
      onClick={
        materialApplicableForProduct
          ? evt => {
              evt.preventDefault();
              evt.stopPropagation();

              if (!isSelected) {
                if (typeof onClick === 'function') {
                  onClick(upholsteryOptionId, materialId, 'customizer');
                }
              } else {
                if (!isTouchDevice || !setOptionAsFav || !removeOptionAsFav) {
                  return;
                }
                // comes here for mobile/tablet double tap handling.
                if (
                  lastTapRef?.current &&
                  Date.now() - lastTapRef.current < DOUBLE_PRESS_DELAY
                ) {
                  if (isFavMaterial) {
                    removeOptionAsFav(materialId);
                    avo.fabricUnfavorited(favParams);
                  } else if (!isFavMaterial) {
                    setOptionAsFav(materialId);
                    avo.fabricFavorited(favParams);
                  }
                  // The favorite notification banner has been removed after a
                  // request from Christoph. If we make this change permanently, we
                  // should remove this line and the favorite notifcation banner code.
                  // showNotificationBannerRef.current = true;
                  lastTapRef.current = null;
                } else {
                  lastTapRef.current = Date.now();
                }
              }
            }
          : null
      }
      onDblClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();
        if (isTouchDevice || !setOptionAsFav || !removeOptionAsFav) {
          return;
        }
        if (isSelected || !materialApplicableForProduct) {
          if (isFavMaterial) {
            if (typeof toggleFlyOut === 'function') {
              toggleFlyOut(false, materialId);
            }
            removeOptionAsFav(materialId);
            avo.fabricUnfavorited(favParams);
          } else {
            setOptionAsFav(materialId);
            avo.fabricFavorited(favParams);
          }
          // As above, remove in future if we make this change permanently.
          // showNotificationBannerRef.current = true;
        }
      }}
      onHover={onHover ? () => onHover(upholsteryOptionId, materialId) : null}
      toggleFlyOut={toggleFlyOut}
      materialId={materialId}
      isRound={isRound}
      isDisabled={isDisabled}
      isFavMaterial={isFavMaterial}
      customToggleFlyOut={customToggleFlyOut}
      customToggleFlyOutData={customToggleFlyOutData}
      isInFlashSale={isInFlashSale}
      materialApplicableForProduct={materialApplicableForProduct}
    />
  );
};

MaterialThumbnail.propTypes = {
  color: PropTypes.string,
  customToggleFlyOut: PropTypes.bool,
  customToggleFlyOutData: PropTypes.object,
  fabricFavProps: PropTypes.object,
  fullHeightThumbnail: PropTypes.bool,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFavMaterial: PropTypes.bool,
  isInFlashSale: PropTypes.bool,
  isRound: PropTypes.bool,
  isSelected: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  materialApplicableForProduct: PropTypes.bool,
  materialId: PropTypes.number,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  show: PropTypes.bool,
  toggleFlyOut: PropTypes.func,
  upholsteryOptionId: PropTypes.number,
  variant: PropTypes.oneOf([
    'PDPCustomerPhotosReviewsModal',
    'PDPShownInUI',
    'PDPShownInUI--grey-background',
  ]),
};

export default MaterialThumbnail;
